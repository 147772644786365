import logo from "./logo.svg";
import "./App.css";
import React, { useState, useEffect } from "react";
import Tap from "./assets/tap1.png";
import confetti from "canvas-confetti";
import { Sheet } from "react-modal-sheet";
import { SpinWheel } from "./components/wheel";
// import Modal from "react-modal";
// import { Wheel } from "react-custom-roulette";
import { useTelegram } from "./hooks/useTelegram";
import { useInterval } from "usehooks-ts";
import { sleep, tap_value, child_care } from "./assets/components";
import axios from "axios";

function App() {
  let { tg, user_id, firstName, lastName, username, language_code, photo_url } =
    useTelegram();

  const [openRedeem, setOpenRedeem] = useState(false);
  const [openModalGenderParty, setOpenModalGenderParty] = useState(false);

  const [openMarket, setOpenMarket] = useState(false);

  const [profitPerHour, setProfitPerHour] = useState(0);

  const [count, setCount] = useState(1000);
  const [costTap, setCostTap] = useState(101);
  const [tire, setTire] = useState(120);
  const [energy, setEnergy] = useState(3000);
  const [maxEnergy, setMaxEnergy] = useState(3000);
  const [pressed, setPressed] = useState(false);

  const [upgrades, setUpgrades] = useState([]);
  const [loader, setLoader] = useState(true);

  // const updateCoins = (coins) => setCount(coins);

  const handleSpinResult = (newCount) => {
    setCount(newCount);
  };

  const setData = (data) => {
    console.log(data);
    setCount(data.user.coins);
    setEnergy(data.user.energy);
    setMaxEnergy(data.user.max_energy);
    setCostTap(data.user.tap_value);
    setUpgrades(data.upgrades);
    setProfitPerHour(data.user.profit_per_hour);
    setLoader(false);
  };

  useEffect(() => {
    const timer = setInterval(() => {
      setEnergy((prevEnergy) => Math.min(prevEnergy + 1, maxEnergy));
    }, 1000);

    return () => clearInterval(timer); // Очищаем таймер при размонтировании
  }, [maxEnergy]);

  // Рассчитываем значение tire при изменении energy
  useEffect(() => {
    setTire(Math.round((energy / maxEnergy) * 120));
  }, [energy, maxEnergy]);

  useEffect(() => {
    tg.ready();
    // console.log(process.env.REACT_APP_API_URL);
    // console.log(tg?.initDataUnsafe?.user);
    axios
      .get(`${process.env.REACT_APP_API_URL}/user`, {
        params: {
          user_id: user_id,
          firstName: firstName,
          lastName: lastName,
          username: username,
          language_code: language_code,
          photo_url: photo_url,
        },
      })
      .then((res) => {
        setData(res.data);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }, []);

  const handleClick = () => {
    if (energy < costTap) {
      return;
    }
    confetti({
      particleCount: 100,
      spread: 100,
      origin: { y: 0.8 },
      colors: ["#ff69b4", "#ffb6c1", "#ff4500", "#ffcc00"],
    });
    setCount(count + costTap);
    setEnergy(energy - costTap);
  };

  useInterval(() => {
    if (!loader) {
      axios
        .post(`${process.env.REACT_APP_API_URL}/coins`, {
          user_id: user_id,
          coins: count,
          energy: energy,
        })
        .then((res) => {
          setData(res.data);
        });
    }
  }, 10000);

  useInterval(() => {
    setCount(count + profitPerHour / 60 / 60);
  }, 1000);

  const handleUpgrade = (upgrade) => {
    axios
      .post(`${process.env.REACT_APP_API_URL}/buy`, {
        user_id: user_id,
        upgrade_id: upgrade.id,
      })
      .then((res) => {
        console.log(res.data);
        setData(res.data);
      });
  };

  return (
    <div className="App">
      {!loader && (
        <>
          <header className="App-header">
            <img
              src={photo_url ? photo_url : logo}
              className="App-logo"
              alt="logo"
            />
            <p>{username ? `${username}` : `${firstName + " " + lastName}`}</p>
            <button onClick={handleClick} className="App-leaderboard">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="24px"
                viewBox="0 -960 960 960"
                width="24px"
                fill="#261f11"
              >
                <path d="M160-200h160v-320H160v320Zm240 0h160v-560H400v560Zm240 0h160v-240H640v240Zm-560 0v-320q0-33 23.5-56.5T160-600h160v-160q0-33 23.5-56.5T400-840h160q33 0 56.5 23.5T640-760v240h160q33 0 56.5 23.5T880-440v240q0 33-23.5 56.5T800-120H160q-33 0-56.5-23.5T80-200Z" />
              </svg>
            </button>
          </header>
          <main className="App-main">
            <div className="App-main-links">
              <button onClick={() => setOpenRedeem(true)}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="24px"
                  viewBox="0 -960 960 960"
                  width="24px"
                  fill="#fff"
                >
                  <path d="M160-280v80h640v-80H160Zm0-440h88q-5-9-6.5-19t-1.5-21q0-50 35-85t85-35q30 0 55.5 15.5T460-826l20 26 20-26q18-24 44-39t56-15q50 0 85 35t35 85q0 11-1.5 21t-6.5 19h88q33 0 56.5 23.5T880-640v440q0 33-23.5 56.5T800-120H160q-33 0-56.5-23.5T80-200v-440q0-33 23.5-56.5T160-720Zm0 320h640v-240H596l60 82q10 14 8 29.5T648-503q-14 10-29.5 7.5T593-511L480-664 367-511q-10 13-25.5 15.5T312-503q-14-10-16.5-25.5T303-558l59-82H160v240Zm200-320q17 0 28.5-11.5T400-760q0-17-11.5-28.5T360-800q-17 0-28.5 11.5T320-760q0 17 11.5 28.5T360-720Zm240 0q17 0 28.5-11.5T640-760q0-17-11.5-28.5T600-800q-17 0-28.5 11.5T560-760q0 17 11.5 28.5T600-720Z" />
                </svg>
              </button>
              <button>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="24px"
                  viewBox="0 -960 960 960"
                  width="24px"
                  fill="#fff"
                >
                  <path d="M240-160q-66 0-113-47T80-320v-320q0-66 47-113t113-47h480q66 0 113 47t47 113v320q0 66-47 113t-113 47H240Zm0-480h480q22 0 42 5t38 16v-21q0-33-23.5-56.5T720-720H240q-33 0-56.5 23.5T160-640v21q18-11 38-16t42-5Zm-74 130 445 108q9 2 18 0t17-8l139-116q-11-15-28-24.5t-37-9.5H240q-26 0-45.5 13.5T166-510Z" />
                </svg>
              </button>
              <button onClick={() => setOpenMarket(true)}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="24px"
                  viewBox="0 -960 960 960"
                  width="24px"
                  fill="#fff"
                >
                  <path d="M201-120q-33 0-56.5-23.5T121-200v-318q-23-21-35.5-54t-.5-72l42-136q8-26 28.5-43t47.5-17h556q27 0 47 16.5t29 43.5l42 136q12 39-.5 71T841-518v318q0 33-23.5 56.5T761-120H201Zm368-440q27 0 41-18.5t11-41.5l-22-140h-78v148q0 21 14 36.5t34 15.5Zm-180 0q23 0 37.5-15.5T441-612v-148h-78l-22 140q-4 24 10.5 42t37.5 18Zm-178 0q18 0 31.5-13t16.5-33l22-154h-78l-40 134q-6 20 6.5 43t41.5 23Zm540 0q29 0 42-23t6-43l-42-134h-76l22 154q3 20 16.5 33t31.5 13ZM201-200h560v-282q-5 2-6.5 2H751q-27 0-47.5-9T663-518q-18 18-41 28t-49 10q-27 0-50.5-10T481-518q-17 18-39.5 28T393-480q-29 0-52.5-10T299-518q-21 21-41.5 29.5T211-480h-4.5q-2.5 0-5.5-2v282Zm560 0H201h560Z" />
                </svg>
              </button>
            </div>
            <div className="App-main-balance">
              <div className="App-main-balance-coins">
                <div className="App-main-balance-text">
                  <h2>Your balance</h2>
                  <p>
                    {count}{" "}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      height="24px"
                      viewBox="0 -960 960 960"
                      width="24px"
                      fill="#4893d8"
                    >
                      <path d="M280-160q-45 0-78.5-28.5T162-262q-38-20-60-57t-22-81q0-53 30.5-94.5T192-552l-72-72-12 12q-11 11-28 11t-28-11q-11-11-11-28t11-28l80-80q11-11 28-11t28 11q11 11 11 28t-11 28l-12 12 56 56 32-94q12-37 43.5-59.5T378-800h204q39 0 70.5 22.5T696-718l54 162q57 11 93.5 55T880-400q0 44-22 81t-60 57q-6 45-39.5 73.5T680-160q-38 0-68.5-22T568-240H392q-13 36-43.5 58T280-160Zm16-400h144v-160h-62q-13 0-23 7.5T340-692l-44 132Zm224 0h144l-44-132q-5-13-15-20.5t-23-7.5h-62v160ZM392-320h176q13-36 43.5-58t68.5-22q30 0 56 14t44 38q9-11 14.5-24.5T800-400q0-33-23.5-56.5T720-480H240q-33 0-56.5 23.5T160-400q0 14 5.5 27.5T180-348q18-24 44-38t56-14q38 0 68.5 22t43.5 58Zm-112 80q17 0 28.5-11.5T320-280q0-17-11.5-28.5T280-320q-17 0-28.5 11.5T240-280q0 17 11.5 28.5T280-240Zm400 0q17 0 28.5-11.5T720-280q0-17-11.5-28.5T680-320q-17 0-28.5 11.5T640-280q0 17 11.5 28.5T680-240ZM480-400Z" />
                    </svg>
                  </p>
                </div>
                <div className="App-main-energy">
                  <p>
                    {energy} / {maxEnergy}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      height="24px"
                      viewBox="0 -960 960 960"
                      width="24px"
                      fill={`hsl(${tire}, 70%, 50%)`}
                    >
                      <path d="M580-490q-21 0-35.5-14.5T530-540q0-21 14.5-35.5T580-590q21 0 35.5 14.5T630-540q0 21-14.5 35.5T580-490Zm-200 0q-21 0-35.5-14.5T330-540q0-21 14.5-35.5T380-590q21 0 35.5 14.5T430-540q0 21-14.5 35.5T380-490Zm100 210q-60 0-108.5-33T300-400h360q-23 54-71.5 87T480-280Zm0 160q-75 0-140.5-28.5t-114-77q-48.5-48.5-77-114T120-480q0-75 28.5-140.5t77-114q48.5-48.5 114-77T480-840q75 0 140.5 28.5t114 77q48.5 48.5 77 114T840-480q0 75-28.5 140.5t-77 114q-48.5 48.5-114 77T480-120Zm0-80q116 0 198-82t82-198q0-116-82-198t-198-82h-12q-6 0-12 2-6 6-8 13t-2 15q0 21 14.5 35.5T496-680q9 0 16.5-3t15.5-3q12 0 20 9t8 21q0 23-21.5 29.5T496-620q-45 0-77.5-32.5T386-730v-6q0-3 1-8-83 30-135 101t-52 163q0 116 82 198t198 82Zm0-280Z" />
                    </svg>
                    {/* от 0 до 120 от красного до зеленого */}
                  </p>
                </div>
              </div>
              <button
                className="App-main-button"
                onClick={() => {
                  handleClick();
                }}
                onTouchStart={() => setPressed(true)}
                onTouchEnd={() => setPressed(false)}
                onMouseEnter={() => setPressed(true)}
                onMouseLeave={() => setPressed(false)}
              >
                <div
                  className={pressed ? "circle active" : "circle"}
                  id="tap-area"
                >
                  <img src={Tap} alt="Clickable" />
                </div>
              </button>
            </div>
          </main>
          <Sheet
            isOpen={openRedeem}
            onClose={() => setOpenRedeem(false)}
            snapPoints={[800]}
            initialSnap={0}
            onSnap={(snap) => console.log(snap)}
          >
            <Sheet.Container>
              <Sheet.Header />
              <Sheet.Content style={{ paddingInline: "1rem" }}>
                <h2 style={{ textAlign: "center", fontSize: "1rem" }}>
                  MiniApps
                </h2>
                <Sheet.Scroller>
                  <button
                    onClick={() => {
                      setOpenModalGenderParty(true);
                      setOpenRedeem(false);
                    }}
                    className="App-active-button"
                  >
                    <p>Gender Party</p>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      height="24px"
                      viewBox="0 -960 960 960"
                      width="24px"
                      fill="#4893d8"
                    >
                      <path d="M480-80q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm-40-83v-40q-35-5-67.5-19T312-256l-28 29q33 26 72.5 42.5T440-163Zm80 0q44-5 83.5-21.5T676-227l-28-29q-28 20-60.5 34T520-203v40Zm-40-117q83 0 141.5-58.5T680-480q0-83-58.5-141.5T480-680q-83 0-141.5 58.5T280-480q0 83 58.5 141.5T480-280Zm253-4q26-33 42.5-72.5T797-440h-40q-5 35-19 67.5T704-312l29 28Zm-506 0 29-29q-20-28-34-60t-19-67h-40q5 44 21.5 83.5T227-284Zm237-57-86-115q-8-11-8-24t8-24l86-115q6-8 16-8t16 8l86 115q8 11 8 24t-8 24l-86 115q-6 8-16 8t-16-8ZM163-520h40q5-35 19-67t34-60l-29-29q-26 33-42.5 72.5T163-520Zm594 0h40q-5-44-22-83.5T732-676l-28 28q20 28 34 60.5t19 67.5ZM313-704q28-20 60-34t67-19v-40q-44 5-83.5 21.5T284-733l29 29Zm335 0 28-28q-33-26-72.5-43T520-797v40q35 5 67.5 19t60.5 34Z" />
                    </svg>
                  </button>
                </Sheet.Scroller>
              </Sheet.Content>
            </Sheet.Container>
            <Sheet.Backdrop onTap={() => setOpenRedeem(false)} />
          </Sheet>
          <Sheet
            isOpen={openMarket}
            onClose={() => setOpenMarket(false)}
            snapPoints={[800]}
            initialSnap={0}
            onSnap={(snap) => console.log(snap)}
          >
            <Sheet.Container>
              <Sheet.Header />
              <Sheet.Content style={{ paddingInline: "1rem" }}>
                <h2 style={{ textAlign: "center", fontSize: "1rem" }}>
                  Market
                </h2>
                <Sheet.Scroller>
                  {upgrades.map((upgrade, idx) => (
                    <button
                      key={idx}
                      disabled={upgrade.max_lvl === upgrade.lvl}
                      onClick={() => {
                        if (
                          upgrade.cost_lvl !== undefined &&
                          count >= upgrade.cost_lvl
                        ) {
                          /*
                          if (upgrade.max_energy !== null) {
                            setMaxEnergy(maxEnergy + upgrade.max_energy);
                          }
                          if (upgrade.tap_value !== null) {
                            setCostTap(costTap + upgrade.tap_value);
                          }
                          if (upgrade.profit_per_hour !== null) {
                            setProfitPerHour(
                              profitPerHour + upgrade.profit_per_hour
                            );
                          }
                          setCount(
                            upgrade.lvl !== undefined
                              ? count -
                                  Math.round(
                                    Math.pow(
                                      upgrade.cost * upgrade.multiple,
                                      upgrade.lvl
                                    )
                                  )
                              : count - upgrade.cost
                          );
                          */
                          handleUpgrade(upgrade);
                        }
                      }}
                      className="App-active-button market"
                    >
                      <div>
                        <p>{upgrade.name}</p>
                        <p>
                          {upgrade.lvl !== undefined
                            ? ` lvl ${upgrade.lvl}`
                            : " lvl 0"}
                        </p>
                      </div>
                      <div>
                        <p>
                          {upgrade.tap_value !== null &&
                          upgrade.lvl !== upgrade.max_lvl
                            ? `+${upgrade.tap_value}`
                            : null}
                          {upgrade.max_energy !== null &&
                          upgrade.lvl !== upgrade.max_lvl
                            ? `+${upgrade.max_energy}`
                            : null}
                          {upgrade.profit_per_hour !== null &&
                          upgrade.lvl !== upgrade.max_lvl
                            ? `+${upgrade.profit_per_hour}`
                            : null}
                          {upgrade.tap_value !== null ? tap_value() : null}
                          {upgrade.max_energy !== null ? child_care() : null}
                          {upgrade.profit_per_hour !== null ? sleep() : null}
                          {upgrade.lvl === upgrade.max_lvl ? "MAX" : null}
                        </p>
                        <p>
                          {upgrade.cost_lvl !== undefined
                            ? upgrade.cost_lvl
                            : ""}
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            height="24px"
                            viewBox="0 -960 960 960"
                            width="24px"
                            fill="#4893d8"
                          >
                            <path d="M280-160q-45 0-78.5-28.5T162-262q-38-20-60-57t-22-81q0-53 30.5-94.5T192-552l-72-72-12 12q-11 11-28 11t-28-11q-11-11-11-28t11-28l80-80q11-11 28-11t28 11q11 11 11 28t-11 28l-12 12 56 56 32-94q12-37 43.5-59.5T378-800h204q39 0 70.5 22.5T696-718l54 162q57 11 93.5 55T880-400q0 44-22 81t-60 57q-6 45-39.5 73.5T680-160q-38 0-68.5-22T568-240H392q-13 36-43.5 58T280-160Zm16-400h144v-160h-62q-13 0-23 7.5T340-692l-44 132Zm224 0h144l-44-132q-5-13-15-20.5t-23-7.5h-62v160ZM392-320h176q13-36 43.5-58t68.5-22q30 0 56 14t44 38q9-11 14.5-24.5T800-400q0-33-23.5-56.5T720-480H240q-33 0-56.5 23.5T160-400q0 14 5.5 27.5T180-348q18-24 44-38t56-14q38 0 68.5 22t43.5 58Zm-112 80q17 0 28.5-11.5T320-280q0-17-11.5-28.5T280-320q-17 0-28.5 11.5T240-280q0 17 11.5 28.5T280-240Zm400 0q17 0 28.5-11.5T720-280q0-17-11.5-28.5T680-320q-17 0-28.5 11.5T640-280q0 17 11.5 28.5T680-240ZM480-400Z" />
                          </svg>
                        </p>
                      </div>
                    </button>
                  ))}
                </Sheet.Scroller>
              </Sheet.Content>
            </Sheet.Container>
            <Sheet.Backdrop onTap={() => setOpenMarket(false)} />
          </Sheet>
          {/* <WheelComponent
            openModalGenderParty={openModalGenderParty}
            updateCoins={updateCoins}
            count={count}
          /> */}
          <SpinWheel
            openModal={openModalGenderParty}
            setOpenModal={setOpenModalGenderParty}
            count={count}
            setCount={handleSpinResult}
          />
        </>
      )}
      {loader && (
        <div className="App-loader">
          <div className="loader"></div>
        </div>
      )}
    </div>
  );
}

export default App;
