const tg = window.Telegram.WebApp;

export function useTelegram() {
  return {
    tg,
    user: tg.initDataUnsafe?.user,
    queryId: tg.initDataUnsafe?.query_id,
    photo_url: tg.initDataUnsafe?.user?.photo_url,
    user_id: tg.initDataUnsafe?.user?.id,
    firstName: tg.initDataUnsafe?.user?.first_name,
    lastName: tg.initDataUnsafe?.user?.last_name,
    username: tg.initDataUnsafe?.user?.username,
    language_code: tg.initDataUnsafe?.user?.language_code,
    onClose: () => tg.close(),
    onToggleButton: () => {
      if (tg.MainButton.isVisible) {
        tg.MainButton.hide();
      } else {
        tg.MainButton.show();
      }
    },
  };
}
