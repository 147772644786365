import React, { useState } from "react";
import Modal from "react-modal";
import { Wheel } from "react-custom-roulette";

export function SpinWheel({ openModal, setOpenModal, count, setCount }) {
  const wheel = [
    { option: "Girl 👧", style: { backgroundColor: "#c75bd1" } },
    { option: "Boy 👦", style: { backgroundColor: "#97c6ff" } },
    { option: "Alien 👽", style: { backgroundColor: "#5bd17e" } },
    { option: "Girl 👧", style: { backgroundColor: "#c75bd1" } },
    { option: "Boy 👦", style: { backgroundColor: "#97c6ff" } },
    { option: "Girl 👧", style: { backgroundColor: "#c75bd1" } },
    { option: "Boy 👦", style: { backgroundColor: "#97c6ff" } },
    { option: "Girl 👧", style: { backgroundColor: "#c75bd1" } },
    { option: "Boy 👦", style: { backgroundColor: "#97c6ff" } },
    { option: "Girl 👧", style: { backgroundColor: "#c75bd1" } },
    { option: "Boy 👦", style: { backgroundColor: "#97c6ff" } },
    { option: "Girl 👧", style: { backgroundColor: "#c75bd1" } },
    { option: "Boy 👦", style: { backgroundColor: "#97c6ff" } },
    { option: "Girl 👧", style: { backgroundColor: "#c75bd1" } },
    { option: "Boy 👦", style: { backgroundColor: "#97c6ff" } },
  ];
  const [mustSpin, setMustSpin] = useState(false);
  const [prizeNumber, setPrizeNumber] = useState(0);

  const handleSpinClick = (gender) => {
    if (count >= 100) {
      if (!mustSpin) {
        const newPrizeNumber = Math.floor(Math.random() * wheel.length);
        setPrizeNumber(newPrizeNumber);
        const newCount = count - 100;
        setCount(newCount);
        setMustSpin(true);
        if (gender === wheel[newPrizeNumber].option) {
          setCount(gender === "Alien 👽" ? newCount + 1400 : newCount + 200);
        }
      }
    }
  };

  return (
    <Modal
      isOpen={openModal}
      onRequestClose={() => setOpenModal(false)}
      ariaHideApp={false}
      style={{
        content: {
          borderRadius: ".625rem",
          display: "flex",
          flexDirection: "column",
          alignItems: "stretch",
          justifyContent: "center",
          gap: "1rem",
          padding: "1rem",
          backgroundColor: "#ffffff",
          height: "fit-content",
          margin: "auto",
          overflow: "clip",
        },
        overlay: { zIndex: 11000 },
      }}
    >
      <Wheel
        mustStartSpinning={mustSpin}
        prizeNumber={prizeNumber}
        data={wheel}
        backgroundColors={["#ffffff", "#ffffff"]}
        textColors={["#ffffff"]}
        outerBorderWidth={1}
        innerBorderWidth={1}
        outerBorderColor={"#0000000f"}
        innerBorderColor={"#0000000f"}
        radiusLineColor={"#0000000f"}
        radiusLineWidth={2}
        spinDuration={1}
        onStopSpinning={() => setMustSpin(false)}
      />
      <div className="App-spin-wheels">
        {["Girl 👧", "Alien 👽", "Boy 👦"].map((gender) => (
          <button
            key={gender}
            onClick={() => handleSpinClick(gender)}
            className="App-spin-wheel"
          >
            <p>100</p>
            <svg
              style={{ alignSelf: "end" }}
              xmlns="http://www.w3.org/2000/svg"
              height="24px"
              viewBox="0 -960 960 960"
              width="24px"
              fill="#fff"
            >
              {/* SVG path content */}
            </svg>
          </button>
        ))}
      </div>
    </Modal>
  );
}
